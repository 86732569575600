<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Editing {{ itemMaster.itemNo }}
            </h1>
            <p class="log_info">
              Created by {{ itemMaster.createdBy ? itemMaster.createdBy.name : '' }} on {{ dateFormatWithTime(itemMaster.createdAt) }} <br>Last updated on {{ dateFormatWithTime(itemMaster.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="itemMasterEditForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-9">
              <b-form-group
                label="Item Name*"
                label-for="h-event-remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Item Name"
                  vid="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="h-event-remarks"
                    v-model="description"
                    placeholder="Item Name"
                    :state="(errors.length > 0 || descriptionValidation) ? false : null"
                    name="remarks"
                    @input="descriptionValidation == true ? descriptionValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="descriptionValidation"
                    class="text-danger"
                  >
                    {{ descriptionError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-3" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Supplier"
                vid="supplier"
                rules="required"
              >
                <b-form-group
                  label="Supplier*"
                  label-for="h-roster-group-supplier"
                  label-cols-md="3"
                  :state="(errors.length > 0 || supplierValidation) ? false : null"
                >
                  <v-select
                    id="h-roster-group-supplier"
                    v-model="supplier"
                    label="companyName"
                    :options="vendorOptions"
                    :reduce="companyName => companyName._id"
                    :clearable="false"
                    @input="supplierValidation == true ? supplierValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="supplierValidation"
                    class="text-danger"
                  >
                    {{ supplierError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Unit"
                vid="unit"
                rules="required"
              >
                <b-form-group
                  label="Unit*"
                  label-for="h-roster-group-unit"
                  label-cols-md="3"
                  :state="(errors.length > 0 || unitValidation) ? false : null"
                >
                  <vue-autosuggest
                    v-model="unit"
                    :suggestions="filteredUnitOptions"
                    :get-suggestion-value="getUnitSuggestionValue"
                    :input-props="{id: 'autosuggest__input', class: 'form-control', placeholder: 'E.g. kg'}"
                    @selected="setUnit"
                    @input="suggestOnInputChangeUnit"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item }}</span>
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="unitValidation"
                    class="text-danger"
                  >
                    {{ unitError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-roster-group-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-roster-group-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'purchasing-items-master-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BButton, BNav, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  // eslint-disable-next-line import/named
  required, maxValue, minValue, numeric, between,
} from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BNav,
    BFormTextarea,

    vSelect,
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      itemMaster: {},
      supplier: '',
      description: '',
      status: 'pending',
      unit: 'Piece(s)',
      unitError: 'Valid unit is required',
      unitValidation: false,
      supplierError: 'Valid supplier is required',
      supplierValidation: false,
      descriptionError: 'Valid operating hours is required',
      descriptionValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      statusOptions: [
        { title: 'Pending', code: 'pending' },
        { title: 'Published', code: 'published' },
        { title: 'Hidden', code: 'hidden' },
      ],
      unitOptions: process.env.VUE_APP_UNIT_LIST.split(','),
      unitTypeOptions: [
        { title: 'Pcs', code: 'Pcs' },
        { title: 'KG', code: 'KG' },
        { title: 'EA', code: 'EA' },
      ],
      vendorOptions: [],
      filteredUnitOptions: [],

      // validation rules
      required,
      numeric,
      maxValue,
      minValue,
      between,
    }
  },
  created() {
    this.$http.get('purchase/vendors/vendor-options')
      .then(response => {
        this.vendorOptions = response.data.vendorOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get(`purchase/items-master/${this.$route.params.id}`)
      .then(response => {
        this.itemMaster = response.data
        this.description = response.data.description || ''

        this.supplier = response.data.supplier._id || ''
        this.unit = response.data.unit || 'Piece(s)'
        this.status = response.data.status || 'pending'
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    suggestOnInputChangeUnit(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.unitOptions.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredUnitOptions = [{
        data: filteredData,
      }]
    },
    getUnitSuggestionValue(suggestion) {
      return suggestion.item
    },
    setUnit(suggestionItem, _suggestionIndex) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.unit = suggestionItem.item
      }
    },
    submitForm() {
      this.$refs.itemMasterEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('description', this.description)
          formData.append('supplier', this.supplier)
          formData.append('unit', this.unit)
          formData.append('status', this.status)

          this.$http.patch(`purchase/items-master/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Item Data Updated.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'purchasing-items-master-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'unit') {
                    this.unitError = validationError.msg
                    this.unitValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'supplier') {
                    this.supplierError = validationError.msg
                    this.supplierValidation = true
                  } else if (validationError.param === 'description') {
                    this.descriptionError = validationError.msg
                    this.descriptionValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group, .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
