var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"create-edit-form"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title"},[_vm._v(" Editing "+_vm._s(_vm.itemMaster.itemNo)+" ")]),_c('p',{staticClass:"log_info"},[_vm._v(" Created by "+_vm._s(_vm.itemMaster.createdBy ? _vm.itemMaster.createdBy.name : '')+" on "+_vm._s(_vm.dateFormatWithTime(_vm.itemMaster.createdAt))+" "),_c('br'),_vm._v("Last updated on "+_vm._s(_vm.dateFormatWithTime(_vm.itemMaster.updatedAt))+" ")])])],1)],1)],1),_c('validation-observer',{ref:"itemMasterEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"md-9"}},[_c('b-form-group',{attrs:{"label":"Item Name*","label-for":"h-event-remarks","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"h-event-remarks","placeholder":"Item Name","state":(errors.length > 0 || _vm.descriptionValidation) ? false : null,"name":"remarks"},on:{"input":function($event){_vm.descriptionValidation == true ? _vm.descriptionValidation = false : null}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.descriptionValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.descriptionError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-3"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Supplier","vid":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Supplier*","label-for":"h-roster-group-supplier","label-cols-md":"3","state":(errors.length > 0 || _vm.supplierValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-roster-group-supplier","label":"companyName","options":_vm.vendorOptions,"reduce":function (companyName) { return companyName._id; },"clearable":false},on:{"input":function($event){_vm.supplierValidation == true ? _vm.supplierValidation = false : null}},model:{value:(_vm.supplier),callback:function ($$v) {_vm.supplier=$$v},expression:"supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.supplierValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.supplierError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Unit","vid":"unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unit*","label-for":"h-roster-group-unit","label-cols-md":"3","state":(errors.length > 0 || _vm.unitValidation) ? false : null}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredUnitOptions,"get-suggestion-value":_vm.getUnitSuggestionValue,"input-props":{id: 'autosuggest__input', class: 'form-control', placeholder: 'E.g. kg'}},on:{"selected":_vm.setUnit,"input":_vm.suggestOnInputChangeUnit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item))])]}}],null,true),model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.unitValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.unitError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-roster-group-status","label-cols-md":"3","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-roster-group-status","label":"title","options":_vm.statusOptions,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'purchasing-items-master-index' }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Changes")])],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }